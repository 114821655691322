import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../layouts'
import './index.css'
import Frun from '../images/kettle-creek.jpg'
import CPine from '../images/pine-creek.jpg'
import Arrowwood from '../images/fyling-horse.jpg'
import Benttree from '../images/bent-tree.jpg'
import CommonHero from '../components/commonhero/commonhero.js'

class IndexPage extends React.Component {
  render() {
    return (
      <Layout head={{ title: 'Black Forest, Colorado' }}>
        <CommonHero
          pageTitle="Black Forest, Colorado"
          custombackground="https://res.cloudinary.com/brian-boals/image/upload/v1562118036/brianboals/black-forest.jpg"
        />
        <main className="bg-white p-relative">
          <div className="container py-5">
            <p>
              The Black Forest area of Colorado Springs is unmistakable, with
              it’s towering pines casting what appears to be a dark shadow along
              the north rim of the city. With home ages ranging from the 1800’s
              to new build, homeowners are drawn to this area for it’s large
              lots and tons of privacy! Although you are minutes from shopping,
              entertainment and restaurants, the forest makes you feel as though
              you’re in the country.
            </p>
            <p>Desirable Academy District 20 serves this area.</p>
          </div>
          <section className="py-5">
            <div className="container">
              <div className="intro text-center mb-5">
                <h2>Neighborhoods</h2>
              </div>
              <div className="row">
                <div className="col-sm-6 col-lg-4 p-1">
                  <div
                    className="area"
                    style={{
                      background: `url(${Arrowwood})`,
                    }}
                  >
                    <div className="area-content">
                      <h4 className="text-white">
                        <a
                          href="https://brianboals-arrowwoodiii.flymycommunity.com/"
                          target="_blank"
                          className="text-white"
                          rel="noopener noreferrer"
                          title="Arrowwood III"
                        >
                          Arrowwood III
                        </a>
                      </h4>
                      <p>
                        Located In Desirable Black Forest on the north end of
                        Colorado Springs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 p-1">
                  <div
                    className="area"
                    style={{
                      background: `url(${CPine})`,
                    }}
                  >
                    <div className="area-content">
                      <h4 className="text-white">
                        <a
                          href="https://brianboals-cathedralpines.flymycommunity.com/"
                          target="_blank"
                          className="text-white"
                          rel="noopener noreferrer"
                          title="Cathedral Pines"
                        >
                          Cathedral Pines
                        </a>
                      </h4>
                      <p>
                        Nestled Amongst Colorado’s Towering Pines, This
                        Neighborhood Boasts Private Lots with Luxury Homes!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 p-1">
                  <div
                    className="area"
                    style={{
                      background: `url(${Frun})`,
                    }}
                  >
                    <div className="area-content">
                      <h4 className="text-white">
                        <a
                          href="https://brianboals-foxrun.flymycommunity.com/"
                          target="_blank"
                          className="text-white"
                          rel="noopener noreferrer"
                          title="Fox Run"
                        >
                          Fox Run
                        </a>
                      </h4>
                      <p>
                        One of Black Forests’ treasures, featuring breathtaking
                        views, tall pines and large lots!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 p-1">
                  <div
                    className="area"
                    style={{
                      background: `url(${Benttree})`,
                    }}
                  >
                    <div className="area-content">
                      <h4 className="text-white">
                        <a
                          href="https://brianboals-benttree.flymycommunity.com/"
                          target="_blank"
                          className="text-white"
                          rel="noopener noreferrer"
                          title="Bent Tree"
                        >
                          Bent Tree
                        </a>
                      </h4>
                      <p>
                        This neighborhood features gorgeous homes located on
                        large lots in desirable Black Forest
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default IndexPage
